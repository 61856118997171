import { Subscription } from 'stompjs'
import { serverHost } from '../../config'
import { WebsocketService } from '../websocket-service'
import { GeneralNotificationDto } from '../../model/notification-dto/general-notification-dto'

export class StudentWebsocketNotificationService extends WebsocketService<GeneralNotificationDto[]> {
  private subscriptionPrivate: Subscription | null = null

  init(callback: (dto: GeneralNotificationDto[]) => void, userId: number) {
    this.initClient(`${serverHost}/ws`)
    this.connect(() => {
      this.subscribeOnPrivateChannel(callback, userId)
    })
  }

  terminate() {
    this.unsubscribeFromPrivateChannel()
    this.disconnect()
  }

  private subscribeOnPrivateChannel(callback: (dto: GeneralNotificationDto[]) => void, userId: number) {
    this.subscribe(`/notification/${userId}`, callback)
  }

  private unsubscribeFromPrivateChannel() {
    return this.subscriptionPrivate?.unsubscribe()
  }
}
