import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { store } from 'react-notifications-component'
import QuoteBlock from '../components/quote-block'
import StudentCourseService from '../../../../services/student-services/student-course-service'
import { useGetAdditionalCourses } from './useGetAdditionalCourses'

import 'react-circular-progressbar/dist/styles.css'
import './course-page.css'
import CourseElement from '../components/course-element/course-element'
import UserCourseService from '../../../../services/common-services/user-course-service'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import { AuthService } from '../../../../services/auth-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import { studentErrorNotyTemplate } from '../../../../config'
import CourseType from '../course-type.model'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'

const fakeStudentCourseService = new FakeOpenStudentCourseService()
const studentCourseService = new StudentCourseService()
const userCourseService = new UserCourseService()

const AllCoursesPage = ({ history, statusPage = '', match: { params: { studentId = -1 } = {} } = {} }) => {
  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const [availableCourses, setAvailableCourses] = useState([])
  const [directionName, setDirectionName] = useState('')
  const [currentUserId, setCurrentUserId] = useState()
  const [currentUserRole, setCurrentUserRole] = useState()
  const additionalCourses = useGetAdditionalCourses(studentId)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const param = urlParams.get('param')
    if (param === 'forbidden') {
      store.addNotification({
        ...studentErrorNotyTemplate,
        message: `${t('AccessIsDenied')}`,
      })
    }
  }, [])

  document.title = `${t('AllCourses')}`

  useEffect(() => {
    setCurrentUserId(AuthService.currentUserValue().id)
    setCurrentUserRole(AuthService.currentUserValue().role.name)
  }, [])

  useEffect(() => {
    let studentIdForRequest = currentUserId
    if (statusPage === 'fake') {
      studentIdForRequest = studentId
    }
    userCourseService.getDirectionName(studentIdForRequest).then(setDirectionName)
  }, [currentUserId])

  useEffect(() => {
    if (studentId === -1) {
      studentCourseService.getAvailableCourses().then(courses => setAvailableCourses(courses))
      return
    }
    if (currentUserRole === RoleEnum.PAY_STUDENT) {
      userCourseService.loadDirectionIdByStudentId(studentId).then(studentDirectionId => {
        userCourseService.loadDirectionIdByStudentId(currentUserId).then(currentUserDirectionId => {
          if (studentDirectionId !== currentUserDirectionId) {
            history.push('/user/courses?param=forbidden')
          }
        })
      })
    } else {
      fakeStudentCourseService.getAvailableCourses(studentId).then(courses => setAvailableCourses(courses))
    }
  }, [studentId, currentUserId, currentUserRole])

  return (
    <>
      <div className="middle-header all-courses">
        <div className="container">
          <div className="page-title-wrap">
            <div className="page-title">{t('AllCourses')}</div>
          </div>
        </div>
      </div>
      <div className="all-course-wrap">
        <div className="container">
          <QuoteBlock />
          <div className="courses-wrap my-courses">
            <div className="courses-title">{`${t('MyCourses')} ${directionName}`}</div>
            <div id="all_course_container" className="row">
              {availableCourses.map(course => (
                <div className="col-md-3 col-sm-4 col-xs-6" key={course.id}>
                  <CourseElement
                    course={course}
                    pic={convertResponseImageUrl(course.coursePicUrl)}
                    studentId={studentId}
                    statusPage={statusPage}
                    courseType={CourseType.Regular}
                  />
                </div>
              ))}
            </div>
            {additionalCourses.length ? (
              <>
                <div className="courses-title">{t('AdditionalCourses')}</div>
                <div id="all_course_container" className="row">
                  {additionalCourses.map(course => (
                    <div className="col-md-3 col-sm-4 col-xs-6" key={course.id}>
                      <CourseElement
                        course={course}
                        pic={convertResponseImageUrl(course.coursePicUrl)}
                        studentId={studentId}
                        statusPage={statusPage}
                        courseType={CourseType.Additional}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(AllCoursesPage)
