import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useReactAlert } from 'src/hooks/useReactAlert'
import ReportFilters from './report-filters'
import ReportList from './report-list'
import StudentReportService from '../../../../services/student-services/student-report-service'
import { StudentReportGetDto } from '../../../../model/student-model'
import { getFirstDayOfMonth, getDayFromMoth, getDayFromRuStringLocal } from './utils/utils'

import styles from './report-page.module.scss'
import ReportCalendar from './report-calendar'
import Spinner from '../../../spinner'
import useSetDocumentTitle from '../../../../hooks/useSetDocumentTitle'

const cx = classNames.bind(styles)

export type FilterCalendar = {
  startPublicDate: string
  endPublicDate: string
  selectedMonthId: number
  selectedYear: number
}
export type TFilterValues = {
  query: string
  isOnlyOwn: boolean
}

interface RefObject<T> {
  current: T
}

export type RefCalendarConfig = RefObject<{
  pointerLeft: number
  pointerRight: number
}>

export type AllReportsByStudents = {
  [key: string]: StudentReportGetDto[]
}

export type StudentReport = {
  [key: string | number]: StudentReportGetDto | null
}

export const MAX_DAYS_PER_VIEW = 5

const ReportPage = () => {
  const { catchErrorAlert } = useReactAlert()
  const { t } = useTranslation()
  useSetDocumentTitle(t('AllReports'))

  const reportService = new StudentReportService()
  const [reports, setReports] = useState<StudentReport>({})
  const [allReports, setAllReports] = useState<StudentReport[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [filterValues, setFilterValues] = useState<TFilterValues>({
    query: '',
    isOnlyOwn: true,
  })

  const [filterCalendar, setFilterCalendar] = useState<FilterCalendar>({
    startPublicDate: getFirstDayOfMonth(new Date().getMonth()),
    endPublicDate: getDayFromMoth(MAX_DAYS_PER_VIEW, new Date().getMonth()),
    selectedMonthId: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
  })

  const calendarConfig = useRef({
    pointerLeft: 1,
    pointerRight: 5,
  })

  const { query, isOnlyOwn } = filterValues

  const { startPublicDate, endPublicDate, selectedMonthId } = filterCalendar
  const legacyItemsPerPage = 1000

  const legacyCurrentPage = 1

  const mapDataToAllReports = (studentReports: StudentReportGetDto[]): AllReportsByStudents => {
    return studentReports.reduce((acc: AllReportsByStudents, report) => {
      if (acc[report.studentId]) {
        acc[report.studentId]?.push(report)
      } else {
        acc[report.studentId] = [report]
      }
      return acc
    }, {})
  }

  const mapReportsToCalendarView = (studentReports: StudentReportGetDto[]) => {
    const result: StudentReport = {}
    let leftPointer = calendarConfig.current.pointerLeft
    const rightPointer = calendarConfig.current.pointerRight

    while (leftPointer <= rightPointer) {
      result[leftPointer] = null
      leftPointer += 1
    }

    studentReports.length &&
      studentReports.forEach(item => {
        const reportDay = getDayFromRuStringLocal(item.reportDate)
        if (!reportDay) {
          return
        }
        if (!result[reportDay]) {
          result[reportDay] = null
        }

        result[reportDay] = item
      })
    return result
  }

  const mapAllReportsToCalendarView = (studentReports: AllReportsByStudents): StudentReport[] => {
    return Object.values(studentReports).map((value: StudentReportGetDto[]) => {
      return mapReportsToCalendarView(value)
    })
  }

  const handleReports = (entities: StudentReportGetDto[]): void => {
    if (!filterValues.isOnlyOwn) {
      const mappedReports = mapAllReportsToCalendarView(mapDataToAllReports(entities))
      setAllReports(mappedReports)
    } else {
      const mappedReports = mapReportsToCalendarView(entities)
      setReports(mappedReports)
    }
  }

  const getStudentReports = useCallback(() => {
    setIsLoading(true)
    reportService
      .getStudentReports(legacyCurrentPage, legacyItemsPerPage, isOnlyOwn, query, startPublicDate, endPublicDate)
      .then(({ entities }) => {
        setIsLoading(false)
        handleReports(entities)
      })
      .catch(err => {
        catchErrorAlert(err)
        setIsLoading(false)
      })
  }, [query, isOnlyOwn, startPublicDate, endPublicDate, selectedMonthId])

  useEffect(() => {
    getStudentReports()
  }, [query, isOnlyOwn, startPublicDate, endPublicDate, selectedMonthId])

  return (
    <>
      <div className={cx('report-wrap')}>
        <div className="container">
          <div className={cx('report-block')}>
            <div className={cx('report-filters')}>
              <ReportFilters
                getStudentReports={getStudentReports}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                setFilterCalendar={setFilterCalendar}
                filterCalendar={filterCalendar}
                calendarConfig={calendarConfig}
                isLoading={isLoading}
              />
            </div>
            <div className={cx('report-calendar-widget', !filterValues.isOnlyOwn && 'report-calendar-sticky')}>
              <ReportCalendar
                startPublicDate={startPublicDate}
                setFilterCalendar={setFilterCalendar}
                isLoading={isLoading}
                calendarConfig={calendarConfig}
                selectedMonthId={filterCalendar.selectedMonthId}
              />
            </div>
            {isLoading ? (
              <div className={cx('report-loader')}>
                <Spinner />
              </div>
            ) : (
              <div className={cx('report-list')}>
                <ReportList
                  reports={reports}
                  allReports={allReports}
                  getStudentReports={getStudentReports}
                  isOnlyOwn={filterValues.isOnlyOwn}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportPage
