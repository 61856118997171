import React, { useEffect } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import AlertTemplate from 'react-alert-template-basic'
import ReactNotification from 'react-notifications-component'
import { positions, Provider as AlertProvider, transitions } from 'react-alert'
import { availableRoutesByInactivation, messagesForInactivationReasons } from '../../model/inactivation-model'
import { AuthService } from '../../services/auth-service'
import { history } from '../../config'
import PrivateRoute from '../private-route'
import LoginPage from '../pages/login-page'
import BlankPage from '../pages/blank-page'
import { StudentPage, FakeStudentPage } from '../pages/student'
import StartPage from '../pages/start'
import PasswordRecoveryContainer from '../pages/login-page/password-recovery/password-recovery-container'
import TokenRegistrationPage from '../pages/registration/registration-page'
import EnrolleePage from '../pages/enrollee/enrollee-page'
import EnrolleeRegistration from '../pages/enrollee/components/enrollee-registration'
import NotFound from '../not-found'
import './root.scss'
import './common.scss'
import './fonts.scss'
import ProtectedRoute from '../protected-route'

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000,
  },
}

const App = () => {
  useEffect(() => {
    history.listen(({ pathname }) => {
      const currentUser = AuthService.currentUserValue()
      if (currentUser?.inactivation) {
        const { reason } = currentUser.inactivation
        const currentRole = currentUser.role.name
        const availableRoutes = availableRoutesByInactivation[reason][currentRole]
        if (!availableRoutes.includes(pathname)) {
          history.replace({
            pathname: availableRoutes[0],
            state: { reasonMessage: messagesForInactivationReasons[reason] },
          })
        }
      }
    })
  }, [])

  const isAuthorized = () => {
    // FIXME: Переделать логику редиректа и проверки авторизации, чтобы зависимость была только от token.
    return Boolean(AuthService.getToken() && AuthService.currentUserValue())
  }

  return (
    <Router history={history}>
      <AlertProvider template={AlertTemplate} {...options}>
        <LastLocationProvider>
          <ReactNotification />
          <Switch>
            <PrivateRoute path="/user" component={StudentPage} authRole={['PAY_STUDENT']} />
            <PrivateRoute path="/enrollee" component={EnrolleePage} authRole={['STUDENT']} />
            <PrivateRoute
              path="/fake"
              component={FakeStudentPage}
              authRole={['MENTOR', 'CHIEF_MENTOR', 'ADMIN', 'PAY_STUDENT', 'CURATOR']}
            />
            <Route path="/" exact component={BlankPage} />
            <ProtectedRoute isAllowed={() => !isAuthorized()} path="/login" component={LoginPage} />
            <Route path="/recovery-pass" component={PasswordRecoveryContainer} />
            <Route path="/registration/enrollee/:hash" exact component={EnrolleeRegistration} />
            <Route path="/registration/:hash" exact component={TokenRegistrationPage} />
            <Route path="/start" component={StartPage} />
            <ProtectedRoute isAllowed={() => isAuthorized()} path="" component={NotFound} />
          </Switch>
        </LastLocationProvider>
      </AlertProvider>
    </Router>
  )
}

export default App
