import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'react-notifications-component'
import { useTranslation } from 'react-i18next'
import { History } from 'history'
import { addAvailableCourseId } from 'src/store/available-courses/availableCoursesSlice'
import { useReactAlert } from 'src/hooks/useReactAlert'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import FakeOpenStudentCourseTaskService from '../../../../services/fake-page-services/open/fake-open-student-course-task-service'
import UserCourseTaskService from '../../../../services/common-services/user-course-task-service'
import UserChapterService from '../../../../services/common-services/user-chapter-service'
import UserModuleService from '../../../../services/common-services/user-module-service'
import getLoadTaskCallback from './getLoadTaskCallback'
import { RootState } from '../../../../store/rootReducer'
import { AuthService } from '../../../../services/auth-service'
import UserNavigationService from '../../../../services/common-services/user-navigation-service'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../config'
import StudentBreadCrumbs from '../components/student-bread-crumbs'
import CommentsBlock from './comment-block/comments-block'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import {
  getReferenceSolutionCallback,
  getSessionStorageNameCallback,
  isCodeTaskExemplarySolutionAvailableCallback,
  isCodeTaskMentorCommentAvailableCallback,
  isCodeTaskSessionStorageAvailableCallback,
  isCodeTaskSolutionHistoryAvailableCallback,
} from './task-block/codeTaskCallbacks'
import getMentorCheckCallback from './getMentorCheckCallback'
import { useOpenFakeAvailability } from './useOpenFakeAvailability'
import {
  AssociationTask,
  CodeTask,
  GappingTask,
  LectureTask,
  MentorCheckTask,
  MultiAnswerTask,
  MultiInputTask,
  MultiTestTask,
  OrderingTask,
  TheoryTask,
  WordTask,
} from '../course-page/task-block'
import usePrevious from './usePrevious'
import RaceConditionGuard from './raceConditionGuard'
import { useEffectSomeDepsChange } from './useEffectSomeDepsChange'
import Spinner from '../../../spinner'
import { getPostResetInterceptorFake } from './getPostResetInterceptorFake'
import { getCheckAvailabilityCallbackFake } from './getCheckAvailabilityCallbackFake'
import getSolveTaskCallbackFake from './getSolveTaskCallbackFake'
import { getPostSolveInterceptorFake } from './getPostSolveInterceptorFake'
import { getCoursePageNextStepUrlFake } from './getCoursePageNextStepUrlFake'
import { TasksTypesUnion } from '../../enrollee/direction-page/direction-page'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'
import UserCourseService from '../../../../services/common-services/user-course-service'
import { TaskType } from '../../../../model/task-dto/task-type-enum'
import ReviewStepTask from './task-block/review-step-task'

interface Props {
  history: History
  location: { pathname: string }
  match: {
    params: {
      coursePosition: number
      modulePosition: number
      chapterPosition: number
      courseTaskPosition: number
      studentId: number
    }
  }
}

const fakeOpenStudentCourseService = new FakeOpenStudentCourseService()
const fakeOpenStudentCourseTaskService = new FakeOpenStudentCourseTaskService()
const userCourseTaskService = new UserCourseTaskService()
const userChapterService = new UserChapterService()
const userModuleService = new UserModuleService()
const raceConditionGuard = new RaceConditionGuard()
const userCourseService = new UserCourseService()

const FakeCoursePage = ({
  history,
  location: { pathname },
  match: {
    params: { coursePosition = 0, modulePosition = 0, chapterPosition = 0, courseTaskPosition = 0, studentId = -1 },
  },
}: Props) => {
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tasksHeading, setTasksHeading] = useState<any>([])
  const [task, setTask] = useState<TasksTypesUnion>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [moduleIsLoading, setModuleIsLoading] = useState<boolean>(true)
  const [chapterIsLoading, setChapterIsLoading] = useState<boolean>(true)
  const [taskTypeLoading, setTaskTypeLoading] = useState<number>(0)

  const [taskType, setTaskType] = useState<TaskType>()
  const [studentCourseTaskInfoId, setStudentCourseTaskInfoId] = useState<number>(0)
  const [nextButtonText, setNextButtonText] = useState<string>(t('NextStep'))
  const { availableCourseIds } = useSelector((state: RootState) => state.availableCourses)
  const [principalRole, setPrincipalRole] = useState<RoleEnum>(RoleEnum.EMPTY)
  const [principalId, setPrincipalId] = useState<number>(0)
  const [courseTaskId, setCourseTaskId] = useState<number>(0)
  const [courseId, setCourseId] = useState<number>(0)
  const [chapterId, setChapterId] = useState<number>(0)
  const [moduleId, setModuleId] = useState<number>(0)
  const [needUpdate, setNeedUpdate] = useState<boolean>(true)
  const [chapterCount, setChapterCount] = useState<number>(0)
  const [moduleCount, setModuleCount] = useState<number>(0)
  const [nextTaskLastInTheCourse, setNextTaskLastInTheCourse] = useState<boolean>(false)
  const [linkForMentor, setLinkForMentor] = useState<string>(``)
  const [isTaskAvailableForPrincipal, setIsTaskAvailableForPrincipal] = useState<boolean>(false)
  const [coursePic, setCoursePic] = useState('')

  const solutionsLink = `/fake/user/${studentId}/courses/${coursePosition}/${modulePosition}/${chapterPosition}/${courseTaskPosition}/solutions`

  const postResetInterceptor = getPostResetInterceptorFake(taskType)

  const loadTasksHeading = () => {
    fakeOpenStudentCourseService
      .getTasksHeading(coursePosition, modulePosition, chapterPosition, courseTaskPosition, studentId)
      .then(newTaskHeading => {
        setTasksHeading(newTaskHeading)
        setIsLoading(true)
        setTaskType(newTaskHeading.taskType)
        setTaskTypeLoading(prevState => prevState + 1)
      })
  }

  const taskService = new FakeOpenStudentCourseTaskService()

  const loadTask = (interceptor = (val: any) => val) => {
    if (!courseTaskId) return Promise.resolve()

    const load = getLoadTaskCallback(courseTaskId, taskType, studentId, taskService)
    return load()
      .then(value => {
        setTask(interceptor(value))
        setTask(value)
        setIsLoading(false)
        return value
      })
      .catch(err => {
        catchErrorAlert(err)

        if (err.code === 423) {
          reactAlert.error(err.text)
          history.replace(`/fake/user/${studentId}/courses/${courseId}`)
        }
      })
  }

  const prevChapterPosition = usePrevious(chapterPosition)
  const prevModulePosition = usePrevious(modulePosition)

  const onSolveTask = getSolveTaskCallbackFake(taskType, courseTaskId)

  const onCheckTask = getMentorCheckCallback(taskType, studentCourseTaskInfoId)

  useEffectSomeDepsChange(() => {
    loadTask()
  }, [courseTaskId, taskTypeLoading, studentId])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useLayoutEffect(() => {
    setModuleIsLoading(true)
  }, [modulePosition])

  useLayoutEffect(() => {
    setChapterIsLoading(true)
  }, [chapterPosition])

  useEffect(() => {
    // @ts-ignore
    setPrincipalRole(AuthService.currentUserValue().role.name)
  }, [])

  useEffect(() => {
    // @ts-ignore
    setPrincipalId(AuthService.currentUserValue().id)
  }, [])

  // only for fake page
  useEffect(() => {
    if (studentId !== -1 && principalRole === RoleEnum.PAY_STUDENT && !availableCourseIds.includes(courseId)) {
      fakeOpenStudentCourseService.loadEnrollingState(courseId, principalId).then(isEnrolled => {
        if (isEnrolled) {
          dispatch(addAvailableCourseId({ courseId }))
        } else {
          history.push('/user/courses?param=forbidden')
        }
      })
    }
  }, [courseId])

  useOpenFakeAvailability(principalRole, taskType, courseTaskId, setIsTaskAvailableForPrincipal)

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if (coursePosition * modulePosition * chapterPosition * courseTaskPosition !== 0) {
      fakeOpenStudentCourseService.getCourseIdByPos(coursePosition, studentId).then(setCourseId)
      loadTasksHeading()
    }
  }, [coursePosition, modulePosition, chapterPosition, courseTaskPosition, studentId])

  useEffect(() => {
    if (courseId * modulePosition !== 0) {
      raceConditionGuard
        .getGuardedPromise(userModuleService.getIdByCourseIdAndPosition(courseId, modulePosition))
        .then(value => {
          setModuleId(value)
          setModuleIsLoading(false)
        })
    }
  }, [courseId, modulePosition])

  useEffect(() => {
    if (moduleId * chapterPosition !== 0 && !moduleIsLoading && prevModulePosition === modulePosition) {
      raceConditionGuard
        .getGuardedPromise(userChapterService.getIdByModuleIdAndPosition(moduleId, chapterPosition))
        .then(setChapterId)
    }
  }, [moduleId, chapterPosition, moduleIsLoading])

  useEffect(() => {
    setChapterIsLoading(false)
  }, [chapterId])

  useEffect(() => {
    if (courseId) {
      userCourseService.getCourseInfo(courseId).then((course: any) => {
        setCoursePic(convertResponseImageUrl(course.coursePicUrl))
      })
    }
  }, [courseId])

  useEffect(() => {
    if (
      chapterId * courseTaskPosition !== 0 &&
      !moduleIsLoading &&
      !chapterIsLoading &&
      prevChapterPosition === chapterPosition &&
      prevModulePosition === modulePosition
    ) {
      userCourseTaskService.getIdByChapterIdAndPosition(chapterId, courseTaskPosition).then(value => {
        setIsLoading(true)
        setCourseTaskId(value)
      })
      UserNavigationService.getChapterNavigationInfoById(chapterId)
        .then(data => {
          setChapterCount(data.currentModuleChapterCount)
          setModuleCount(data.moduleCount)
        })
        .catch(err => {
          catchErrorAlert(err)
        })
    }
  }, [chapterId, courseTaskPosition, moduleIsLoading, chapterIsLoading])

  useEffect(() => {
    if (courseTaskId !== 0) {
      // @ts-ignore
      userCourseTaskService.isNextTaskLastInTheCourse(courseTaskId).then(setNextTaskLastInTheCourse)
      fakeOpenStudentCourseTaskService
        .getStudentCourseTaskIdByCourseTaskId(courseTaskId, studentId)
        // @ts-ignore
        .then(setStudentCourseTaskInfoId)
    }
  }, [courseTaskId])

  useEffect(() => {
    if (studentCourseTaskInfoId !== 0) {
      setLinkForMentor(pathname)
    }
  }, [studentCourseTaskInfoId])

  useEffect(() => {
    if (needUpdate) {
      loadTasksHeading()
      setNeedUpdate(false)
    }
  }, [needUpdate])

  const hasNextTask = () => {
    return tasksHeading.taskHeadingDtos.length > parseInt(String(courseTaskPosition), 10)
  }

  const hasNextChapter = () => {
    return chapterCount > chapterPosition
  }

  const hasNextModule = () => {
    return moduleCount > modulePosition
  }

  const linkToMentor = () => {
    navigator.clipboard
      .writeText(`${linkForMentor}`)
      .then(() => {
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `${t('LinkCopiedToClipboard')}`,
        })
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `${t('FailedToCopyLink')}`,
        })
      })
  }

  const urlNextStep = getCoursePageNextStepUrlFake(
    hasNextTask,
    hasNextChapter,
    hasNextModule,
    studentId,
    coursePosition,
    modulePosition,
    chapterPosition,
    courseTaskPosition
  )

  const nextStepButtonUrl = () => {
    history.push(urlNextStep())
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  let taskRender

  if (nextTaskLastInTheCourse) {
    if (nextButtonText !== `${t('ToCourseList')}`) {
      setNextButtonText(`${t('ToCourseList')}`)
    }
  } else if (nextButtonText !== `${t('NextStep')}`) {
    setNextButtonText(`${t('NextStep')}`)
  }

  const isCodeTaskExemplarySolutionAvailable = isCodeTaskExemplarySolutionAvailableCallback(principalRole)
  const isCodeTaskSolutionHistoryAvailable = isCodeTaskSolutionHistoryAvailableCallback(
    principalRole,
    'fake',
    isTaskAvailableForPrincipal
  )
  const isCodeTaskMentorCommentAvailable = isCodeTaskMentorCommentAvailableCallback('fake', isTaskAvailableForPrincipal)
  const isCodeTaskSessionStorageAvailable = isCodeTaskSessionStorageAvailableCallback('fake')
  const getCodeTaskExemplarySolution = getReferenceSolutionCallback(courseTaskId)
  const getSessionStorageName = getSessionStorageNameCallback(principalId, taskType, courseTaskId)

  let mentorTaskSolved
  let mentorTaskChecked
  let mentorTaskAvailable

  if (!isLoading && task) {
    if (tasksHeading.taskHeadingDtos.length < courseTaskPosition) {
      mentorTaskSolved = false
      mentorTaskChecked = false
      mentorTaskAvailable = false
    } else {
      mentorTaskSolved = tasksHeading.taskHeadingDtos[courseTaskPosition - 1].solved
      mentorTaskChecked = tasksHeading.taskHeadingDtos[courseTaskPosition - 1].checked
      mentorTaskAvailable = tasksHeading.taskHeadingDtos[courseTaskPosition - 1].available
    }

    switch (task.type) {
      case TaskType.Lecture:
        taskRender = (
          <LectureTask
            lectureTask={task}
            onSolveTask={onSolveTask}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.Code:
        taskRender = (
          <CodeTask
            codeTask={task}
            loadTask={loadTask}
            isExemplarySolutionAvailableCallback={isCodeTaskExemplarySolutionAvailable}
            isSolutionHistoryAvailableCallback={isCodeTaskSolutionHistoryAvailable}
            isMentorCommentAvailableCallback={isCodeTaskMentorCommentAvailable}
            isSessionStorageAvailableCallback={isCodeTaskSessionStorageAvailable}
            getExemplarySolution={getCodeTaskExemplarySolution}
            sessionStorageName={getSessionStorageName()}
            onSolveTask={onSolveTask}
            onResetTask={() => Promise.resolve()}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
            solutionsLink={solutionsLink}
            courseTaskId={courseTaskId}
          />
        )
        break
      case TaskType.Theory:
        taskRender = (
          <TheoryTask
            theoryTask={task}
            loadTask={loadTask}
            onSolveTask={onSolveTask}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
            onResetTask={() => Promise.resolve()}
            postResetInterceptor={postResetInterceptor}
          />
        )
        break
      case TaskType.Ordering:
        taskRender = (
          <OrderingTask
            orderingTask={task}
            loadTask={loadTask}
            onSolveTask={onSolveTask}
            onResetTask={() => Promise.resolve()}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.Association:
        taskRender = (
          <AssociationTask
            associationTask={task}
            loadTask={loadTask}
            onResetTask={() => Promise.resolve()}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.MultiInput:
        taskRender = (
          <MultiInputTask
            multiInputTask={task}
            loadTask={loadTask}
            onResetTask={() => Promise.resolve()}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.MultiTest:
        taskRender = (
          <MultiTestTask
            multiTestTask={task}
            loadTask={loadTask}
            onResetTask={() => Promise.resolve()}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.MultiAnswer:
        taskRender = (
          <MultiAnswerTask
            multiAnswerTask={task}
            loadTask={loadTask}
            onResetTask={() => Promise.resolve()}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.Gapping:
        taskRender = (
          <GappingTask
            gappingTask={task}
            loadTask={loadTask}
            onResetTask={() => Promise.resolve()}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.Word:
        taskRender = (
          <WordTask
            wordTask={task}
            onResetTask={() => Promise.resolve()}
            onGetTask={loadTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
          />
        )
        break
      case TaskType.MentorCheckTask:
        taskRender = (
          <MentorCheckTask
            solved={mentorTaskSolved}
            checked={mentorTaskChecked}
            handleCheckRequest={() => setNeedUpdate(true)}
            statusPage="fake"
            loadTask={loadTask}
            mentorCheckTask={task}
            onSolveTask={onSolveTask}
            onCheckTask={onCheckTask}
            postSolveInterceptor={getPostSolveInterceptorFake(task.type)}
            isAnswerAvailable={isTaskAvailableForPrincipal}
            solutionsLink={solutionsLink}
            isPermittedToCheckCallback={getCheckAvailabilityCallbackFake(principalRole)}
            role={principalRole}
          />
        )
        break
      case TaskType.ReviewStep:
        taskRender = (
          <ReviewStepTask
            reviewStepTask={task}
            loadReviewDto={() => Promise.resolve()}
            isReviewAssignable={false}
            moduleId={moduleId}
            available={mentorTaskAvailable}
            completed={mentorTaskSolved}
          />
        )
        break
      default:
        taskRender = (
          <div className="task-loader">
            <Spinner />
          </div>
        )
    }
  } else {
    taskRender = (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <StudentBreadCrumbs
        coursePosition={coursePosition}
        modulePosition={modulePosition}
        chapterPosition={chapterPosition}
        courseTaskPosition={courseTaskPosition}
        chapterId={chapterId}
        courseId={courseId}
        tasksHeading={tasksHeading}
        statusPage="fake"
        studentId={studentId}
        isLoading={isLoading}
        coursePic={coursePic}
      />
      <div className="step-content-wrap">
        <div className="container">
          <div className="step-content lesson">{taskRender}</div>
          <button type="button" className="next-step-btn" onClick={nextStepButtonUrl}>
            <span>{nextButtonText}</span>
            <i className="mdi mdi-chevron-right" />
          </button>
          {principalRole === 'PAY_STUDENT' && (
            <p>
              <button type="button" className="btn btn-light" style={{ verticalAlign: 'top' }} onClick={linkToMentor}>
                {t('CopyLinkForMentor')}
              </button>
            </p>
          )}
        </div>
      </div>
      <CommentsBlock taskId={task?.taskId ?? 0} statusPage="fake" principalRole={principalRole} />
    </>
  )
}

export default FakeCoursePage
