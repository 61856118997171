import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { store } from 'react-notifications-component'
import { Button } from 'react-bootstrap'
import CKEditor, { EditorChangeEvent } from 'ckeditor4-react'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { MentorCheckTaskAnswerType, MentorCheckTaskDto } from '../../../../../../model/task-model/MentorCheckTaskDto'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../../config'
import Spinner from '../../../../../spinner'
import TaskDescription from '../task-description'
import AlertDanger from '../alert-danger'
import FakePageCheckPredProjectModal from '../../../../fake-page/fake-page-check-predproject-modal/fake-page-check-predproject-modal'
import { Answer } from './answer'
import { SafetyLocalStorage } from '../../../../../../storage'

interface IProps {
  handleCheckRequest: () => void
  solved: boolean
  checked: boolean
  statusPage: string
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MentorCheckTaskDto>
  mentorCheckTask: MentorCheckTaskDto
  onSolveTask: any // (answer: string) => Promise<string>
  postSolveInterceptor: any // (
  //   previousSolveStatus: boolean,
  //   currentSolveStatus: boolean,
  //   points: number,
  //   dispatch: Dispatch<any>
  // ) => void
  onCheckTask?: any // используется для фейковой страницы, внутри фейковый сервис - не типизирован
  isPermittedToCheckCallback: () => boolean
  isAnswerAvailable: boolean
  solutionsLink: string
  role: RoleEnum
}

const CheckTask: FC<IProps> = ({
  handleCheckRequest,
  solved,
  checked,
  statusPage,
  loadTask,
  mentorCheckTask,
  onSolveTask,
  postSolveInterceptor,
  onCheckTask,
  isPermittedToCheckCallback,
  isAnswerAvailable,
  solutionsLink,
  role,
}) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const [newAnswer, setNewAnswer] = useState<string>(``)
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [errorLink, setErrorLink] = useState<boolean>(false)
  const [errorMassage, setErrorMassage] = useState<string>('')
  const [mentorComment, setMentorComment] = useState<string>('')
  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState<boolean>(false)
  const [right, setRight] = useState<boolean>(false)
  const [lastAnswerId, setLastAnswerId] = useState<number>()
  const [modalShowed, setModalShowed] = useState<boolean>(false)

  const [inputClassName, setInputClassName] = useState<string>('')
  const [lessonClassName, setLessonClassName] = useState<string>('')
  const [icon, setIcon] = useState<JSX.Element | null>()
  const [message, setMessage] = useState<string | null>('')
  const [mentorCheckResult, setMentorCheckResult] = useState<string>('')
  const [solutionSent, setSolutionSent] = useState<boolean>(false)

  const { pathname } = useLocation()

  const MENTOR_COMMENT_KEY = `MENTOR_COMMENT-${pathname}`

  const loadMentorCheckTask = (interceptor?: any) => {
    return loadTask(interceptor).then(task => {
      if (task !== undefined && task !== null) {
        setTaskLoaded(false)
        setLastActionIsRight(task.lastActionIsRight)
        setLastActionIsWrong(task.lastActionIsWrong)
        setLastAnswerId(task.answerId)
      }
      if (solvingTask) {
        setSolvingTask(false)
      }
      return task
    })
  }

  useEffect(() => {
    if (mentorCheckTask !== undefined && mentorCheckTask !== null) {
      setTaskLoaded(false)
      setLastActionIsRight(mentorCheckTask.lastActionIsRight)
      setLastActionIsWrong(mentorCheckTask.lastActionIsWrong)
      setLastAnswerId(mentorCheckTask.answerId)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    const storedMentorComment = SafetyLocalStorage.getItem(MENTOR_COMMENT_KEY)
    if (storedMentorComment) {
      setMentorComment(storedMentorComment)
    }
  }, [])

  const updateComments = ({ editor }: EditorChangeEvent) => {
    const changedMentorComment = editor.getData()
    SafetyLocalStorage.setItem(MENTOR_COMMENT_KEY, changedMentorComment)
    setMentorComment(changedMentorComment)
  }

  const getInputClassName = (isSolved: boolean, isChecked: boolean, isRight: boolean, isWrong: boolean) => {
    let className = 'lesson-input '

    if (isSolved && !isChecked) {
      return `${className} on-check`
    }
    className += isRight ? ` success` : ` error`

    if (!isRight && !isWrong) {
      return 'lesson-input'
    }
    return className
  }

  const getLessonClassName = (isRight: boolean, isWrong: boolean) => {
    if (!isRight && !isWrong) {
      return `lesson-result`
    }
    return isRight ? `lesson-result success` : `lesson-result error`
  }

  const getIcon = (isRight: boolean, isWrong: boolean, isChecked: boolean) => {
    if (!isChecked) {
      return null
    }
    if (!isRight && !isWrong) {
      return null
    }
    return isRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  }

  const getMessage = (isRight: boolean, isWrong: boolean, isChecked: boolean) => {
    if (!isChecked) {
      return null
    }
    if (!isRight && !isWrong) {
      return ''
    }
    return isRight ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
  }

  const getMentorCheckResult = (checkResult: string, isSolved: boolean, isChecked: boolean) => {
    let mentorCheckResultLocal = checkResult
    if (!mentorCheckResultLocal || mentorCheckResultLocal === '') {
      if (isSolved) {
        if (isChecked) {
          mentorCheckResultLocal = t('MentorCheckingSuccess')
        } else {
          mentorCheckResultLocal = t('MentorChecking')
        }
      } else if (checked) {
        mentorCheckResultLocal = t('MentorCheckingFailure')
      }
    }
    return mentorCheckResultLocal
  }

  const reloadTaskFeedback = (
    isSolved: boolean,
    isChecked: boolean,
    isRight: boolean,
    isWrong: boolean,
    checkResult: string
  ) => {
    setInputClassName(getInputClassName(isSolved, isChecked, isRight, isWrong))
    setLessonClassName(getLessonClassName(isRight, isWrong))
    setIcon(getIcon(isRight, isWrong, isChecked))
    setMessage(getMessage(isRight, isWrong, isChecked))
    setMentorCheckResult(getMentorCheckResult(checkResult, isSolved, isChecked))
  }

  const solveMentorCheckTask = () => {
    const { solved: previousSolveStatus } = mentorCheckTask
    setErrorLink(false)
    onSolveTask(newAnswer)
      .then(() => {
        handleCheckRequest()
        return loadMentorCheckTask()
      })
      .then((task: MentorCheckTaskDto) => {
        postSolveInterceptor(previousSolveStatus, task.solved, task.taskPoints, dispatch)
        reloadTaskFeedback(task.solved, task.resolved, task.lastActionIsRight, task.lastActionIsWrong, task.result)
        setSolutionSent(true)
      })
      .catch((err: any) => {
        const { code, text } = err
        if (code === 400) {
          setSolutionSent(false)
          setErrorLink(true)
          setErrorMassage(text)
          setSolvingTask(false)
        }
      })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewAnswer(event.target.value)
  }

  const sendSolution = () => {
    onCheckTask(mentorComment, lastAnswerId, right)
      .then(() => {
        setModalShowed(false)
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `Результат проверки успешно сохранен`,
        })
        SafetyLocalStorage.removeItem(MENTOR_COMMENT_KEY)
        loadMentorCheckTask()
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `Во время сохранения результата проверки произошла ошибка`,
        })
        loadMentorCheckTask()
      })
  }

  useEffect(() => {
    if (mentorCheckTask) {
      const { answer, resolved } = mentorCheckTask
      const chooseAnswer = resolved ? `` : answer || ``
      setNewAnswer(chooseAnswer)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (mentorCheckTask) {
      if (solvingTask) {
        setSolvingTask(false)
      }
      const { answerId, result } = mentorCheckTask
      setTaskLoaded(false)
      const storedMentorComment = SafetyLocalStorage.getItem(MENTOR_COMMENT_KEY)
      if (result) {
        setMentorComment(result)
      } else if (storedMentorComment) {
        setMentorComment(storedMentorComment)
      }
      setLastAnswerId(answerId)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (!solvingTask) {
      return
    }
    solveMentorCheckTask()
  }, [solvingTask])

  useEffect(() => {
    reloadTaskFeedback(solved, checked, lastActionIsRight, lastActionIsWrong, mentorCheckTask.result)
  }, [lastActionIsWrong, lastActionIsRight, solved, checked])

  if (taskLoaded || mentorCheckTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const onClose = () => {
    setModalShowed(false)
  }

  const canBeChecked = isPermittedToCheckCallback()
  const submitButtonDisabled = newAnswer.length === 0

  const isLinkAnswer = [MentorCheckTaskAnswerType.Link, MentorCheckTaskAnswerType.UniqueLink].includes(
    mentorCheckTask.answerType
  )

  return (
    <div className="task-content">
      <div className="step-content-head">{t('TheTaskVerifiedByTheMentor')}</div>
      {mentorCheckTask && mentorCheckTask.description && <TaskDescription description={mentorCheckTask.description} />}
      {statusPage !== 'fake' && (
        <>
          <input
            type="text"
            className={inputClassName}
            style={{ color: `black` }}
            name="lesson-answer"
            onChange={handleChange}
            placeholder={t('EnterYourLink')}
            value={newAnswer || ''}
            disabled={lastActionIsRight || (solved && !checked) || solutionSent}
          />
          {errorLink && <AlertDanger description={errorMassage} />}
          {/* eslint-disable-next-line */}
          {(solved && !checked || checked || solutionSent) && (
            <div className="compile-result">
              <div className="compile-title-wrap">
                <div className="compile-title">{t('MentorCheckResult')}</div>
              </div>
              <div className="terminal-result">
                <samp>
                  <p className="mentor-check-task-message">
                    {solved && !checked ? `Решение находится на проверке` : mentorCheckResult}
                  </p>
                </samp>
              </div>
            </div>
          )}
          <div className="lesson-result-row">
            {checked && (
              <div className={lessonClassName}>
                {icon}
                <span>{message}</span>
              </div>
            )}
            <div className="lesson-result-right-wrap">
              {solvingTask ? (
                <button type="button" disabled className="send-result-btn" style={{ cursor: 'pointer' }}>
                  {t('SubmittingSolution')}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setSolvingTask(true)}
                  className="send-result-btn"
                  disabled={submitButtonDisabled || (solved && !checked) || lastActionIsRight || solutionSent}
                >
                  {t('SubmitSolution')}
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {statusPage === 'fake' && newAnswer && (
        <>
          {isAnswerAvailable && (
            <div style={{ marginBottom: '32px' }}>
              <label htmlFor="mentor-link">{isLinkAnswer ? 'Ссылка на репозиторий:' : 'Ответ:'}</label>
              <div className="fake-page-mentor-link">
                <Answer value={newAnswer} type={mentorCheckTask.answerType} />
              </div>
            </div>
          )}
          {role !== RoleEnum.PAY_STUDENT && mentorCheckTask.prevResult && (
            <div className="compile-result">
              <div className="compile-title-wrap">
                <div className="compile-title">Предыдущий результат проверки:</div>
              </div>
              <div className="answer-result">
                <TaskDescription description={mentorCheckTask.prevResult} isMentorAnswer />
              </div>
            </div>
          )}
          <div className="mentor-check-form">
            {canBeChecked && (
              <>
                <CKEditor name="description" onChange={updateComments} data={mentorComment} />

                <div className="right">
                  <Button className="mt-2" variant="success" onClick={() => setModalShowed(true)} size="lg">
                    Сохранить результат проверки
                  </Button>
                </div>
              </>
            )}
          </div>
          {isAnswerAvailable && (
            <div className="allComments">
              <Link to={solutionsLink} className="history-link">
                {t('AllComments')}
              </Link>
            </div>
          )}
          {canBeChecked && (
            <FakePageCheckPredProjectModal
              modalShowed={modalShowed}
              onClose={onClose}
              sendSolution={sendSolution}
              right={right}
              setRight={setRight}
              lastActionIsRight={lastActionIsRight}
            />
          )}
        </>
      )}
      {canBeChecked && !newAnswer && <div className="no-solution-history">Не было попыток решить задачу</div>}
    </div>
  )
}

export default CheckTask
