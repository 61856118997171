import React, { useState, useRef, useMemo, Dispatch, SetStateAction } from 'react'
import { format } from 'date-fns'
import classNames from 'classnames/bind'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { FilterCalendar, MAX_DAYS_PER_VIEW, RefCalendarConfig } from '../report-page'
import { getDayFromMoth, getAllDaysInMonth } from '../utils/utils'
import styles from './report-calendar.module.scss'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'

const cx = classNames.bind(styles)
type Props = {
  startPublicDate: string
  setFilterCalendar: Dispatch<SetStateAction<FilterCalendar>>
  isLoading: boolean
  calendarConfig: RefCalendarConfig
  selectedMonthId: number
}

//TODO: figure out why it is impossible to pass Swiper interface to a generic swiperRef
export type SwiperInstanceRef = {
  slidePrev?: any
  slideNext?: any
  activeIndex?: number
  isEnd?: boolean
  realIndex?: number
}

const ReportCalendar = ({ startPublicDate, setFilterCalendar, isLoading, calendarConfig, selectedMonthId }: Props) => {
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(false)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)
  const swiperRef = useRef<SwiperInstanceRef>({})

  const formattedStartPublicDate = new Date(startPublicDate)
  const allDaysFromCurrentMonth = getAllDaysInMonth(formattedStartPublicDate.getFullYear(), selectedMonthId)

  const calendarMappedDays = useMemo(() => {
    return [...allDaysFromCurrentMonth].map((item: Date, index: number) => {
      return {
        id: index,
        day: format(item, 'yyyy-MM-dd'),
        formattedDay: item?.toLocaleDateString(['ru'], { day: 'numeric', month: 'long', weekday: 'long' }),
        isDisabled: Date.now() < item.getTime(),
      }
    })
  }, [selectedMonthId])

  const renderDayTextInfo = (date: string) => {
    const [month, day, weakDay] = date.split(' ')
    const formattedToViewMonth = month?.replace(',', '')
    return (
      <>
        <span className={cx('slide__month')}>{formattedToViewMonth}</span>
        <h6 className={cx('slide__day')}>{day}</h6>
        <span className={cx('slide__month')}>{weakDay}</span>
      </>
    )
  }

  const updateCalendarHeaderOnChangeSlide = ({ isNextSlide }: { isNextSlide: boolean }) => {
    if (isNextSlide) {
      if (calendarConfig.current.pointerRight === 30) {
        calendarConfig.current.pointerRight += 1
        calendarConfig.current.pointerLeft += 1
      } else {
        calendarConfig.current.pointerLeft += MAX_DAYS_PER_VIEW
        calendarConfig.current.pointerRight += MAX_DAYS_PER_VIEW
      }
    } else if (calendarConfig.current.pointerRight === 31) {
      calendarConfig.current.pointerRight -= 1
      calendarConfig.current.pointerLeft -= 1
    } else {
      calendarConfig.current.pointerLeft -= MAX_DAYS_PER_VIEW
      calendarConfig.current.pointerRight -= MAX_DAYS_PER_VIEW
    }

    setFilterCalendar(prevState => ({
      ...prevState,
      startPublicDate: getDayFromMoth(
        calendarConfig.current.pointerLeft,
        prevState.selectedMonthId,
        prevState.selectedYear
      ),
      endPublicDate: getDayFromMoth(
        calendarConfig.current.pointerRight,
        prevState.selectedMonthId,
        prevState.selectedYear
      ),
    }))
  }

  const handlePrevSlide = () => {
    if (swiperRef.current.activeIndex === 0) {
      setIsPrevBtnDisabled(true)
    } else {
      setIsNextBtnDisabled(false)
      swiperRef.current?.slidePrev()

      updateCalendarHeaderOnChangeSlide({ isNextSlide: false })
    }
  }

  const handleNexSlide = () => {
    if (swiperRef.current.isEnd) {
      setIsNextBtnDisabled(true)
    } else {
      setIsPrevBtnDisabled(false)
      swiperRef.current?.slideNext()

      updateCalendarHeaderOnChangeSlide({ isNextSlide: true })
    }
  }

  return (
    <div className={cx('report-calendar')}>
      <button
        type="button"
        className={cx('report-calendar__prev-btn', 'report-calendar__btn')}
        disabled={isPrevBtnDisabled || isLoading}
        onClick={handlePrevSlide}
      >
        &lt;
      </button>
      <Swiper
        longSwipes={false}
        allowTouchMove={false}
        slidesPerView={MAX_DAYS_PER_VIEW}
        slidesPerGroup={MAX_DAYS_PER_VIEW}
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        modules={[Navigation]}
        className="swiper report-calendar__swiper"
      >
        {calendarMappedDays.length &&
          calendarMappedDays.map((item, index) => (
            <SwiperSlide className={cx('swiper-slide slide')} key={item?.day || index}>
              <div className={cx('slide__wrapper')}>
                <div className={cx('slide__day', item.isDisabled && 'slide__day--disabled')}>
                  {renderDayTextInfo(item.formattedDay)}
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      <button
        className={cx('report-calendar__next-btn', 'report-calendar__btn')}
        onClick={handleNexSlide}
        disabled={isNextBtnDisabled || isLoading}
        type="button"
      >
        &gt;
      </button>
    </div>
  )
}
export default ReportCalendar
