import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import ReportModal from '../report-modal'
import { FilterCalendar, RefCalendarConfig, TFilterValues } from '../report-page'
import styles from './report-filters.module.scss'
import { months } from '../../../../../utils/select-state/select-state-arrays'
import { getDayFromMoth } from '../utils/utils'
import { SwiperInstanceRef } from '../report-calendar/report-calendar'
import { FIRST_MONTH_INDEX, LAST_MONTH_INDEX, UNPERMITTED_INDEXES } from '../report-calendar/report-canstants'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'

const cx = classNames.bind(styles)
type Props = {
  getStudentReports: () => void
  setFilterValues: Dispatch<SetStateAction<TFilterValues>>
  filterValues: TFilterValues
  setFilterCalendar: Dispatch<SetStateAction<FilterCalendar>>
  calendarConfig: RefCalendarConfig
  filterCalendar: FilterCalendar
  isLoading: boolean
}
const ReportFilters: FC<Props> = ({
  getStudentReports,
  setFilterValues,
  filterValues,
  setFilterCalendar,
  calendarConfig,
  filterCalendar,
  isLoading,
}) => {
  const { query, isOnlyOwn } = filterValues
  const { t } = useTranslation()

  const [showReportModal, setShowReportModal] = useState<boolean>(false)
  const swiperDateRef = useRef<SwiperInstanceRef>()

  const checkIsNextSlideDisabledToSwipe = () => {
    const isCurrentMonth = new Date().getMonth() === filterCalendar.selectedMonthId
    const isCurrentYear = filterCalendar.selectedYear === new Date().getFullYear()

    return isLoading || (isCurrentMonth && isCurrentYear)
  }

  const handlePrevSlideChange = () => {
    let currentSlideIndex = swiperDateRef.current?.realIndex! - 1
    let { selectedYear } = filterCalendar
    const { pointerLeft, pointerRight } = calendarConfig.current

    if (UNPERMITTED_INDEXES.includes(currentSlideIndex)) {
      currentSlideIndex = LAST_MONTH_INDEX
    }

    if (currentSlideIndex === LAST_MONTH_INDEX) {
      selectedYear = new Date().getFullYear() - 1
    }

    setFilterCalendar({
      selectedYear,
      selectedMonthId: currentSlideIndex,
      startPublicDate: getDayFromMoth(pointerLeft, currentSlideIndex, selectedYear),
      endPublicDate: getDayFromMoth(pointerRight, currentSlideIndex, selectedYear),
    })

    swiperDateRef.current?.slidePrev()
  }

  const handleNextSlideChange = () => {
    let currentSlideIndex = swiperDateRef.current?.realIndex! + 1
    let { selectedYear } = filterCalendar
    const { pointerLeft, pointerRight } = calendarConfig.current

    if (UNPERMITTED_INDEXES.includes(currentSlideIndex)) {
      currentSlideIndex = FIRST_MONTH_INDEX
    }

    if (currentSlideIndex === FIRST_MONTH_INDEX) {
      selectedYear = new Date().getFullYear()
    }

    setFilterCalendar({
      selectedYear,
      selectedMonthId: currentSlideIndex,
      startPublicDate: getDayFromMoth(pointerLeft, currentSlideIndex, selectedYear),
      endPublicDate: getDayFromMoth(pointerRight, currentSlideIndex, selectedYear),
    })

    swiperDateRef.current?.slideNext()
  }

  return (
    <>
      <ReportModal
        show={showReportModal}
        onClose={() => {
          setShowReportModal(false)
        }}
        getStudentReports={getStudentReports}
      />
      <div className={cx('filters')}>
        <div className={cx('filter-search')}>
          <form
            className={cx('search-form')}
            onSubmit={event => {
              event.preventDefault()
            }}
          >
            <div className={cx('search-input')}>
              <h5>
                {t('SearchReports')} <i className="mdi mdi-magnify" />
              </h5>
              <div className={cx('search-wrap')}>
                <input
                  type="search"
                  className={cx('search')}
                  id="search"
                  onChange={({ target: { value } }) => setFilterValues(values => ({ ...values, query: value }))}
                  value={query}
                  placeholder={t('ReportSearchPlaceholder')}
                />
              </div>
              <div className={cx('checkbox-wrap')}>
                <input
                  id="isOnlyOwn"
                  name="isOnlyOwn"
                  type="checkbox"
                  checked={isOnlyOwn}
                  onChange={() => {
                    setFilterValues(values => {
                      const { isOnlyOwn: isOnlySelf, query: currentQuery } = values
                      return { ...values, isOnlyOwn: !isOnlySelf, query: !isOnlyOwn ? '' : currentQuery }
                    })
                  }}
                />
                <label htmlFor="isOnlyOwn">{t('OnlyOwn')}</label>
              </div>
            </div>
            <div className={cx('select-date-wrapper')}>
              <button
                type="button"
                className={cx('report-select-date__prev-btn', 'report-select-date__btn')}
                disabled={isLoading}
                onClick={handlePrevSlideChange}
              >
                &lt;
              </button>
              <Swiper
                loop
                navigation={{
                  nextEl: '.review-swiper-button-next',
                  prevEl: '.review-swiper-button-prev',
                }}
                modules={[Navigation]}
                allowTouchMove={false}
                initialSlide={filterCalendar.selectedMonthId}
                onBeforeInit={swiper => {
                  swiperDateRef.current = swiper
                }}
                className={cx('select-date')}
              >
                {months.map((month: { name: string; value: number }) => (
                  <SwiperSlide key={month.value} className={cx('select-date__card')}>
                    <span className={cx('select-date__month')}>{month.name}</span>
                    <span className={cx('select-date__year')}>{filterCalendar.selectedYear}</span>
                  </SwiperSlide>
                ))}
              </Swiper>
              <button
                className={cx('report-select-date__next-btn', 'report-select-date__btn')}
                disabled={checkIsNextSlideDisabledToSwipe()}
                onClick={handleNextSlideChange}
                type="button"
              >
                &gt;
              </button>
            </div>
          </form>
        </div>
        <div className={cx('filter-button')}>
          <button type="button" className={cx('add-report-button')} onClick={() => setShowReportModal(true)}>
            {t('AddReport')}
          </button>
        </div>
      </div>
    </>
  )
}
export default ReportFilters
