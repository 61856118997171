import { createBrowserHistory } from 'history'
import { ReactNotificationOptions } from 'react-notifications-component'

// eslint-disable-next-line import/no-mutable-exports
let serverHost: string
// eslint-disable-next-line import/no-mutable-exports
let adminServerHost: string
// eslint-disable-next-line import/no-mutable-exports
let showReportForStudent: boolean
// eslint-disable-next-line import/no-mutable-exports
let showCommentsForEnrollee: boolean

//! Отключить возможность выбора языка для всех
const showChangeLanguageSelect = false

if (process.env.REACT_APP_MODE === 'dev-backend') {
  // DEV стэнд. Для запуска использовать команду: REACT_APP_MODE=dev-backend npm start
  serverHost = 'https://platform-dev.kata.academy'
  adminServerHost = 'https://admin-dev.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = true
} else if (process.env.REACT_APP_MODE === 'qa-backend') {
  // QA стэнд. Для запуска использовать команду: REACT_APP_MODE=qa-backend npm start
  serverHost = 'https://platform-qa.kata.academy'
  adminServerHost = 'https://admin-qa.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = true
} else if (process.env.REACT_APP_MODE === 'aqaj-backend') {
  // AQAJ стэнд. Для запуска использовать команду: REACT_APP_MODE=aqaj-backend npm start
  serverHost = 'https://platform-aqaj.kata.academy'
  adminServerHost = 'https://admin-aqaj.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = true
} else if (process.env.REACT_APP_MODE === 'local-backend') {
  // Локальный сервер. Для запуска использовать команду: REACT_APP_MODE=local-backend npm start
  serverHost = 'http://localhost:5555'
  adminServerHost = 'https://admin-dev.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = true
} else if (process.env.REACT_APP_MODE === 'release-backend') {
  // Релизный сервер. Для запуска использовать команду: REACT_APP_MODE=release-backend npm start
  serverHost = 'https://platform.kata.academy'
  adminServerHost = 'https://admin.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = false
} else if (process.env.REACT_APP_MODE === 'pre-backend') {
  // Пре релизный сервер. Для запуска использовать команду: REACT_APP_MODE=pre-backend npm start
  serverHost = 'https://platform-pre.kata.academy'
  adminServerHost = 'https://admin-pre.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = false
} else {
  // работа без профиля в релизной среде
  serverHost = ''
  adminServerHost = 'https://admin.kata.academy'
  showReportForStudent = true
  showCommentsForEnrollee = false
}

const notificationUpdateRate = 30000
const history = createBrowserHistory()
const studentSuccessNotyTemplate: ReactNotificationOptions = {
  // title: 'Успешно!',
  type: 'success',
  insert: 'top',
  container: 'top-left',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true,
  },
}

const studentErrorNotyTemplate: ReactNotificationOptions = {
  // title: 'Ошибка!',
  type: 'danger',
  insert: 'top',
  container: 'top-left',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true,
  },
}

const infoNotyTemplate: ReactNotificationOptions = {
  type: 'info',
  insert: 'top',
  container: 'top-left',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
  },
}

const userEditSuccessTemplate: ReactNotificationOptions = {
  // title: 'Успешно!',
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true,
  },
}

const reviewsPeriods = [
  `12:00 - 13:00`,
  `13:00 - 14:00`,
  `14:00 - 15:00`,
  `15:00 - 16:00`,
  `16:00 - 17:00`,
  `17:00 - 18:00`,
  `18:00 - 19:00`,
  `20:00 - 21:00`,
  `21:00 - 22:00`,
]

const zoomAccounts = [
  `info@java-mentor.com`,
  `jmzoom1@yandex.ru`,
  `jmzoom2@yandex.ru`,
  `jmzoom3@yandex.ru`,
  `jmzoom4@yandex.ru`,
  `jmzoom5@mail.ru`,
  `jmzoom6@mail.ru`,
  `jmzoom1_fe@mail.ru`,
  `jmzoom2_fe@mail.ru`,
]

export {
  serverHost,
  adminServerHost,
  history,
  notificationUpdateRate,
  studentSuccessNotyTemplate,
  studentErrorNotyTemplate,
  infoNotyTemplate,
  showChangeLanguageSelect,
  showReportForStudent,
  showCommentsForEnrollee,
  reviewsPeriods,
  zoomAccounts,
  userEditSuccessTemplate,
}
